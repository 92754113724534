.background {
  width: 100vw; 
  height: 100vh; 
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  background-image: url('../src/images/bgimage_desktop.jpg');
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
}

.logo {
  width: 20em;
  margin-left:50px;

}

h2 {
  color: white;
  text-align: center;
  font-family: laguna;
  font-size: 2.5em;
  text-shadow: 0 0 2px black;
  opacity: 1;
}

h3 {
  color: white;
  text-align: center;
  font-family: laguna;
  font-size: 1.5em;
  /* text-shadow: 0 0 0.05px black; */
}

h4 {
  color: white;
  text-align: center;
  font-family: laguna;
  font-size: 1.5em;
  text-shadow: 0 0 0.1px black;
  font-weight: bold
}

a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

@media screen and (max-width: 913px) {
  .background {
    background-image: url('../src/images/bgimage_tablet.jpg');
  }

  .logo {
    width: 16em;
  }

  h2 {
    font-size: 2em
  }

  h3 {
    /* color: blue; */
    font-size: 1.3em
  }

  h4 {
    /* color: blue; */
    font-size: 1.6em
  }
}

@media screen and (max-width: 550px) {
  .background {
      /* background-image: url('../public/images/bgimg_mobile.jpeg')   */

  }

  .logo {
    width: 10em;
  }

  h2 {
    font-size: 1.4em
  }

  h3 {
    /* color: blue; */
    font-size: 1em
  }

  h4 {
    /* color: blue; */
    font-size: 1em
  }
}

@media screen and (max-width: 350px) {
  .background {
      /* background-image: url('../public/images/bgimg_mobile.jpeg')   */

  }

  .logo {
    width: 8em;
  }

  h2 {
    font-size: 1.1em
  }

  h3 {
    font-size: 0.6em
  }

  h4 {
    font-size: 0.8em
  }
}

@font-face {
  font-family: "laguna";
  src:url('../public/fonts/lacuna/lacuna.ttf') format("opentype");
  }


